<template>
  <div class="detail-medical-info">
    <v-card class="card-top mt-4 mb-4 mr-4 ml-4">
      <CustomSection class="pa-0">
        <v-container>
          <v-row>
            <heading-card class="title" :title="dataFilter[$route.params.id]" />
          </v-row>
          <v-row>
            <p class="description subtitle-1">
              {{ $t("the") }} {{ dataFilter[$route.params.id] }}
              {{ $t("detail_the") }}
            </p>
          </v-row>
          <v-row>
            <v-col>
              <localized-link
                :to="`/patient/profile`"
                class="link-detail pt-2 text-uppercase"
              >
                <v-btn class="back-button">
                  <v-icon class="icon"> mdi-arrow-left </v-icon>
                </v-btn>
              </localized-link>
            </v-col>
            <v-col>
              <v-btn-toggle
                class="float-right mr-5"
                v-model="filterMedicalRecord"
                dense
              >
                <!-- <v-btn value="today">
                  <span class="hidden-sm-and-down font-weight-bold">24H</span>
                </v-btn>
                <v-btn valign="middle" value="seven_days_ago">
                  <span class="hidden-sm-and-down font-weight-bold">7D</span>
                </v-btn> -->
                <v-btn valign="middle" value="thirty_days_ago">
                  <span class="font-weight-bold">30D</span>
                </v-btn>
                <v-btn valign="middle" value="ninety_days_ago">
                  <span class="font-weight-bold">90D</span>
                </v-btn>
                <v-btn valign="middle" value="one_year_ago_date">
                  <span class="font-weight-bold">1Y</span>
                </v-btn>
                <v-btn valign="middle" value="life_time_date">
                  <span class="font-weight-bold">MAX</span>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col>
              <ApexMedicalChart :data="dataChart" v-if="dataChart != null" />

              <!-- <ChartMedical :data="dataChart" /> -->
            </v-col>
          </v-row>
        </v-container>
      </CustomSection>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
// import ChartDetailMedicalInfo from "@/components/general/ChartDetailMedicalInfo.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
// import ChartMedical from "@/views/v2/Patient/Chart/ChartMedical.vue";
import ApexMedicalChart from "@/views/v2/Patient/Chart/ApexMedicalChart.vue";

import API from "../../../../service/api_service";
import Auth from "../../../../service/auth_service";

export default {
  components: {
    CustomSection,
    // ChartMedical,
    // ChartDetailMedicalInfo,
    HeadingCard,
    ApexMedicalChart,
  },
  data: () => ({
    filterMedicalRecord: "thirty_days_ago",
    dataFilter: [],
    dataChart: [],
  }),
  watch: {
    filterMedicalRecord() {
      this.$store.commit("setHelper", {
        ...this.$store.state.helper,
        filter_time: this.filterMedicalRecord,
      });
      this.getData();
    },
  },
  created() {
    this.getDataFilter()
    this.filterMedicalRecord = this.$store.state.helper.filter_time;
    this.getData();
  },
  methods: {
    async getDataFilter() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-column-filter`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        let filterGroup = {};
        let keys = [];
        Object.keys(resp.results).forEach(key => {
          const indexfind = keys.findIndex(value => key.includes(value));
          if (indexfind < 0) {
            keys.push(key);
          }
        });
        keys.forEach(key => {
          filterGroup[key] =
            resp.results[
              this.$i18n.locale == "id" ? key : `${key}_${this.$i18n.locale}`
            ];
        });

        this.dataFilter = filterGroup;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      if (
        typeof this.$route.query.medical == "string" &&
        this.$route.query.medical != ""
      ) {
        const profileMedic = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-by-medical-record?show_by=${this.filterMedicalRecord}&medical_record_number=${this.$route.query.medical}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        const { vitality_sign } = profileMedic.results;
        const sorting = vitality_sign[0][
          this.$route.params.id
        ].list_history.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        this.dataChart = sorting;
      } else {
        try {
          let resp = await API.get(
            `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-chart?show_by=${this.filterMedicalRecord}`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          if (resp.results.length > 0) {
            this.dataChart = resp.results[0][
              this.$route.params.id
            ].list_history.sort((a, b) => {
              return new Date(a.date) - new Date(b.date);
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.row-button {
  float: right;
  margin-right: 15px;
  margin-top: 10px;
}

.back-button {
  float: left;
  box-shadow: none;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 44px;
}
.title {
  margin-top: 15px;
  margin-left: 15px;
}
.description {
  margin-left: 15px;
}

.icon {
  font-size: 20px;
}
</style>
